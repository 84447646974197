import { useLocation } from "react-router-dom";
import { getPlanRedirectUrl, savePlanRedirectUrl } from "../utils/common";

export const useSaveRedirectLocation = () => {
  const location = useLocation();
  // 保存location
  const saveLocation = () => {
    savePlanRedirectUrl(location);
  };
  // 获取location
  const getLocation = () => {
    return getPlanRedirectUrl();
  };
  return {
    saveLocation,
    getLocation,
  };
};
