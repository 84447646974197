import React, { Suspense, lazy } from "react";
import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, RefineSnackbarProvider } from "@refinedev/mui";
import { ThemedLayoutV2 } from "./components/themedLayout";
import { ToastContainer } from "react-toastify";
import { DataProvider } from "@refinedev/strapi-v4";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerProvider, {
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { notificationProvider } from "./notificationProvider";
import { authProvider } from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { AuthDialog, Loading } from "./components";
import { AuthDialogProvider } from "./contexts/auth-dialog";
import {
  request,
  companyRequest,
  commentRequest,
  BASE_URL,
  BASE_URL_COMPANY,
  BASE_URL_COMMENT,
} from "./utils/request";
import { ThemeProvider } from "@mui/material";
import { customTheme } from "./customTheme";
import { ChatContextProvider } from "./contexts/chat-context";
// import { OpenReplayProvider } from "./contexts/openreplay";
import Checkout from "./pages/checkout";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/home"));
const ChatId = lazy(() => import("./pages/company/chat/id"));
const Profile = lazy(() => import("./pages/profile"));
const Feedback = lazy(() => import("./pages/feedback"));
const Company = lazy(() => import("./pages/company"));
const Translate = lazy(() => import("./pages/company/translate"));
const FaqId = lazy(() => import("./pages/company/analysis/id"));
const NewsId = lazy(() => import("./pages/company/news/id"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
// const UBIForum = lazy(() => import("./pages/forum"));
const Rank = lazy(() => import("./pages/rank"));
const Calendar = lazy(() => import("./pages/calendar"));
const DeepTranslation = lazy(() => import("./pages/deepTranslation"));
const DeepTranslationId = lazy(() => import("./pages/deepTranslation/id"));
const Subscription = lazy(() => import("./pages/subscription"));
const News = lazy(() => import("./pages/news"));
const Disclaimer = lazy(() => import("./pages/disclaimer"));
const Plan = lazy(() => import("./pages/plan"));
const Success = lazy(() => import("./pages/success"));
const Cancel = lazy(() => import("./pages/cancel"));

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: string) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          {/*<OpenReplayProvider>*/}
          <AuthDialogProvider>
            <ThemeProvider theme={customTheme}>
              <CssBaseline />
              <GlobalStyles
                styles={{ html: { WebkitFontSmoothing: "auto" } }}
              />
              <RefineSnackbarProvider>
                <DevtoolsProvider>
                  <Refine
                    i18nProvider={i18nProvider}
                    dataProvider={{
                      default: DataProvider(BASE_URL, request),
                      company: DataProvider(BASE_URL_COMPANY, companyRequest),
                      comment: DataProvider(BASE_URL_COMMENT, commentRequest),
                    }}
                    notificationProvider={notificationProvider}
                    routerProvider={routerProvider}
                    authProvider={authProvider}
                    options={{
                      reactQuery: {
                        devtoolConfig: {
                          initialIsOpen: false,
                          position: "bottom-left",
                        },
                        clientConfig: {
                          defaultOptions: {
                            queries: {
                              staleTime: Infinity,
                            },
                          },
                        },
                      },
                      syncWithLocation: true,
                      warnWhenUnsavedChanges: true,
                      projectId: "7waEd7-4t6N35-TXmX30",
                    }}
                  >
                    <Suspense
                      fallback={
                        <div className="h-screen flex justify-center items-center">
                          <Loading />
                        </div>
                      }
                    >
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/rank" element={<Rank />} />
                        <Route path="/disclaimer" element={<Disclaimer />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/plan" element={<Plan />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/cancel" element={<Cancel />} />
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/deep-translation">
                          <Route index element={<DeepTranslation />} />
                          <Route path=":cik" element={<DeepTranslationId />} />
                        </Route>
                        <Route
                          element={
                            <ThemedLayoutV2
                              Sider={() => null}
                              Header={() => null}
                            >
                              <Outlet />
                            </ThemedLayoutV2>
                          }
                        >
                          <Route path="/company">
                            <Route
                              index
                              element={
                                <ChatContextProvider>
                                  <Company />
                                </ChatContextProvider>
                              }
                            />
                            <Route
                              element={
                                <Authenticated
                                  fallback={<CatchAllNavigate to="/" />}
                                  v3LegacyAuthProviderCompatible={false}
                                  key={"docCompany"}
                                >
                                  <Outlet />
                                </Authenticated>
                              }
                            >
                              <Route path="/company/news">
                                <Route path=":id" element={<NewsId />} />
                              </Route>
                              <Route path="/company/chat">
                                <Route
                                  path=":id"
                                  element={
                                    <ChatContextProvider>
                                      <ChatId />
                                    </ChatContextProvider>
                                  }
                                />
                                <Route path="*" element={<ErrorComponent />} />
                              </Route>
                              <Route
                                path="/company/translate"
                                element={<Translate />}
                              />
                              <Route path="/company/analysis">
                                <Route path=":id" element={<FaqId />} />
                              </Route>
                              <Route path="*" element={<ErrorComponent />} />
                            </Route>
                          </Route>
                          <Route
                            element={
                              <Authenticated
                                fallback={<CatchAllNavigate to="/" />}
                                v3LegacyAuthProviderCompatible={false}
                                key="doc"
                              >
                                <Outlet />
                              </Authenticated>
                            }
                          >
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route
                              path="/subscription"
                              element={<Subscription />}
                            />
                            <Route path="*" element={<ErrorComponent />} />
                          </Route>
                        </Route>
                        <Route
                          path="/reset-password"
                          element={<ResetPassword />}
                        />
                      </Routes>
                    </Suspense>
                    <AuthDialog />
                    <RefineKbar />
                    <UnsavedChangesNotifier />
                    <ToastContainer />
                  </Refine>
                </DevtoolsProvider>
              </RefineSnackbarProvider>
            </ThemeProvider>
          </AuthDialogProvider>
          {/*</OpenReplayProvider>*/}
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
