import { styled } from "@mui/material/styles";
import { Tab } from "@mui/material";
import React from "react";

interface StyledTabProps {
  label: string;
}

const tabStyles = {
  mx: { xs: 2, lg: 3 },
  pb: { xs: 0, lg: 1 },
  px: 0,
  minWidth: 0,
  fontSize: { xs: "14px", lg: "16px" },
  color: "#000000",
  textTransform: "none",
  fontWeight: { xs: "500", lg: "500" },
};

export const MTab = styled((props: StyledTabProps) => (
  <Tab disableRipple sx={tabStyles} {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "var(--primary-2B363E)",
  "&.Mui-selected": {
    color: "var(--primary-2B363E)",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));
