import React from "react";
import { useTranslate } from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { useAuthDialog } from "../../../contexts/auth-dialog";
import { useSaveRedirectLocation } from "../../../hooks/useSaveRedirectLocation";
import unsubscribeBg from "/plan/unsubscribe_bg@2x.png";

const AuthTranslate = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { closeAuthDialog } = useAuthDialog();
  const { saveLocation } = useSaveRedirectLocation();
  // 关闭弹窗
  const onClose = () => {
    closeAuthDialog();
  };
  // 跳转plan
  const onSubscribe = () => {
    saveLocation();
    navigate("/plan");
    closeAuthDialog();
  };
  return (
    <div className="py-8 md:py-10 px-10 md:px-12">
      <h5 className="pb-4 md:pb-6 text-2xl font-semibold text-center">
        {translate("translate.title", "Unsubscribe")}
      </h5>
      <p className="text-center text-sm md:text-base">
        {translate("translate.doc")}
      </p>
      <div className="py-8 md:pt-12 md:pb-16 flex items-center justify-center">
        <img
          src={unsubscribeBg}
          alt="unsubscribeBg"
          className="h-[80px] md:h-[92px]"
        />
      </div>
      <div className="flex justify-between items-center gap-x-2 md:gap-x-6">
        <button
          onClick={onClose}
          className="w-[220px] h-10 md:h-12 bg-[var(--primary-EFF4FB)] flex justify-center items-center text-[var(--primary-95A9C6)] text-xs md:text-lg rounded-full"
        >
          {translate("translate.buttons.cancel", "cancel")}
        </button>
        <button
          onClick={onSubscribe}
          className="search-button w-[220px] h-10 md:h-12  bg-[var(--primary-394FF6)] flex justify-center items-center  text-white text-xs md:text-lg rounded-full disabled:cursor-not-allowed"
        >
          {translate("translate.buttons.subscribe", "subscribe")}
        </button>
      </div>
    </div>
  );
};

export default AuthTranslate;
